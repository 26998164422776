import React, { Component } from 'react'
import './Mio.css'

export class Mio extends Component {
    render() {
        return (
            <div id="mio"/>
        )
    }
}

export default Mio
